
          @import "src/styles/variables/fonts.scss"; 
          @import "src/styles/variables/breakpoints.scss"; 
        
html,
body {
  height: 100%;
}

body {
  margin: 0;

  @include phone {
    background-size: cover;
    backdrop-filter: none;
  }
}
