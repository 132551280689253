
          @import "src/styles/variables/fonts.scss"; 
          @import "src/styles/variables/breakpoints.scss"; 
        
.loader {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border: 3px solid;
  border-color: #3c9cff #3c9cff transparent;
  border-radius: 50%;
  animation: rotation 1s linear infinite;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    margin: auto;
    border: 3px solid;
    border-color: transparent #f556f8 #f556f8;
    border-radius: 50%;
    transform-origin: center center;
    animation: rotationBack 0.5s linear infinite;
    content: '';
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
