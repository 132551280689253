$small-phone-width: 360px;
$phone-width: 768px;
$tablet-width: 1024px;
$desktop-width: 1200px;

@mixin smallPhone {
  @media screen and (max-width: $small-phone-width - 1) {
    @content;
  }
}

@mixin phone {
  @media screen and (max-width: $phone-width - 1) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: $tablet-width - 1) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: $desktop-width - 1) {
    @content;
  }
}
